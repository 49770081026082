<template>
  <div class="container-xxl bg-white p-0">
    <!-- Navbar Start -->
    <nav
      class="navbar navbar-expand-lg bg-white navbar-light shadow sticky-top p-0"
    >
      <a
        href="/"
        class="navbar-brand d-flex align-items-center text-center py-0 px-4 px-lg-5"
      >
        <h1 class="m-0 text-primary">Umurava Job Portal</h1>
      </a>
      <button
        type="button"
        class="navbar-toggler me-4"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <div class="navbar-nav ms-auto p-4 p-lg-0">
          <a href="/" class="nav-item nav-link active">Home</a>
          <a href="/about" class="nav-item nav-link">About</a>
          <div class="nav-item dropdown">
            <a
              href="#"
              class="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              >Jobs</a
            >
            <div class="dropdown-menu rounded-0 m-0">
              <a href="/job-list" class="dropdown-item">Job List</a>
              <a href="/job-category" class="dropdown-item">Job Category</a>
            </div>
          </div>
          <a href="/contact" class="nav-item nav-link">Contact</a>
        </div>
        <a
          href=""
          class="btn btn-primary rounded-0 py-4 px-lg-5 d-none d-lg-block"
          >Post A Job<i class="fa fa-arrow-right ms-3"></i
        ></a>
      </div>
    </nav>
    <!-- Navbar End -->

    <!-- Carousel Start -->
    <div class="container-fluid p-0">
      <div class="carousel-header">
        <div class="owl-carousel-item position-relative">
          <img class="img-fluid" src="img/umurava-linkedln.jpeg" alt="" />
          <div
            class="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
            style="background: rgba(43, 57, 64, 0.5)"
          >
            <div class="container">
              <div class="row justify-content-start">
                <div class="col-10 col-lg-8">
                  <h1 class="display-3 text-white animated slideInDown mb-4">
                    Find The Perfect Job That You Deserved
                  </h1>
                  <p class="fs-5 fw-medium text-white mb-4 pb-2">
                    Struggling to find your dream job? We can help! Our platform
                    connects you with the perfect opportunity, making your
                    search for a fulfilling and well-deserved career a breeze.
                  </p>
                  <a
                    href=""
                    class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >Search A Job</a
                  >
                  <a
                    href=""
                    class="btn btn-secondary py-md-3 px-md-5 animated slideInRight"
                    >Find A Talent</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Carousel End -->

    <!-- Search Start -->
    <div
      class="container-fluid bg-primary mb-5 wow fadeIn"
      data-wow-delay="0.1s"
      style="padding: 35px"
    >
      <div class="container">
        <div class="row g-2">
          <div class="col-md-10">
            <div class="row g-2">
              <div class="col-md-4">
                <input
                  type="text"
                  class="form-control border-0"
                  placeholder="Keyword"
                />
              </div>
              <div class="col-md-4">
                <select class="form-select border-0">
                  <option selected>Category</option>
                  <option value="1">Category 1</option>
                  <option value="2">Category 2</option>
                  <option value="3">Category 3</option>
                </select>
              </div>
              <div class="col-md-4">
                <select class="form-select border-0">
                  <option selected>Location</option>
                  <option value="1">Location 1</option>
                  <option value="2">Location 2</option>
                  <option value="3">Location 3</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <button class="btn btn-dark border-0 w-100">Search</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Search End -->

    <!-- Category Start -->
    <div class="container-xxl py-5">
      <div class="container">
        <h1 class="text-center mb-5 wow fadeInUp" data-wow-delay="0.1s">
          Explore By Category
        </h1>
        <div class="row g-4">
          <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
            <a class="cat-item rounded p-4" href="/job-list">
              <i class="fa fa-3x fa-mail-bulk text-primary mb-4"></i>
              <h6 class="mb-3">Marketing</h6>
              <p class="mb-0">123 Vacancy</p>
            </a>
          </div>
          <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
            <a class="cat-item rounded p-4" href="/job-list">
              <i class="fa fa-3x fa-headset text-primary mb-4"></i>
              <h6 class="mb-3">Customer Service</h6>
              <p class="mb-0">123 Vacancy</p>
            </a>
          </div>
          <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
            <a class="cat-item rounded p-4" href="/job-list">
              <i class="fa fa-3x fa-user-tie text-primary mb-4"></i>
              <h6 class="mb-3">Human Resource</h6>
              <p class="mb-0">123 Vacancy</p>
            </a>
          </div>
          <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
            <a class="cat-item rounded p-4" href="/job-list">
              <i class="fa fa-3x fa-tasks text-primary mb-4"></i>
              <h6 class="mb-3">Project Management</h6>
              <p class="mb-0">123 Vacancy</p>
            </a>
          </div>
          <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
            <a class="cat-item rounded p-4" href="/job-list">
              <i class="fa fa-3x fa-chart-line text-primary mb-4"></i>
              <h6 class="mb-3">Business Development</h6>
              <p class="mb-0">123 Vacancy</p>
            </a>
          </div>
          <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
            <a class="cat-item rounded p-4" href="/job-list">
              <i class="fa fa-3x fa-hands-helping text-primary mb-4"></i>
              <h6 class="mb-3">Sales & Communication</h6>
              <p class="mb-0">123 Vacancy</p>
            </a>
          </div>
          <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
            <a class="cat-item rounded p-4" href="/job-list">
              <i class="fa fa-3x fa-book-reader text-primary mb-4"></i>
              <h6 class="mb-3">Teaching & Education</h6>
              <p class="mb-0">123 Vacancy</p>
            </a>
          </div>
          <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
            <a class="cat-item rounded p-4" href="/job-list">
              <i class="fa fa-3x fa-drafting-compass text-primary mb-4"></i>
              <h6 class="mb-3">Design & Creative</h6>
              <p class="mb-0">123 Vacancy</p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- Category End -->

    <!-- About Start -->
    <div class="container-xxl py-5">
      <div class="container">
        <div class="row g-5 align-items-center">
          <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
            <div class="row g-0 about-bg rounded overflow-hidden">
              <div class="col-6 text-start">
                <img class="img-fluid w-100" src="img/about1.jpeg" />
              </div>
              <div class="col-6 text-start">
                <img
                  class="img-fluid"
                  src="img/about2.jpeg"
                  style="width: 85%; margin-top: 15%"
                />
              </div>
              <div class="col-6 text-end">
                <img
                  class="img-fluid"
                  src="img/about3.jpeg"
                  style="width: 85%"
                />
              </div>
              <div class="col-6 text-end">
                <img class="img-fluid w-100" src="img/about4.jpeg" />
              </div>
            </div>
          </div>
          <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
            <h1 class="mb-4">We Help To Get The Best Job And Find A Talent</h1>
            <p class="mb-4">
              We streamline the hiring process, helping talented individuals
              find their dream jobs and businesses discover top performers. Let
              us bridge the gap and fuel your professional journey.
            </p>
            <p>
              <i class="fa fa-check text-primary me-3"></i>The best way to
              predict your future is to create it
            </p>
            <p>
              <i class="fa fa-check text-primary me-3"></i>The right people in
              the right place can change the world
            </p>
            <p>
              <i class="fa fa-check text-primary me-3"></i>the only way to do
              great work is to love what you do
            </p>
            <a class="btn btn-primary py-3 px-5 mt-3" href="">Read More</a>
          </div>
        </div>
      </div>
    </div>
    <!-- About End -->

    <!-- Jobs Start -->
    <div class="container-xxl py-5">
      <div class="container">
        <h1 class="text-center mb-5 wow fadeInUp" data-wow-delay="0.1s">
          Job Listing
        </h1>
        <div class="tab-class text-center wow fadeInUp" data-wow-delay="0.3s">
          <ul
            class="nav nav-pills d-inline-flex justify-content-center border-bottom mb-5"
          >
            <li class="nav-item">
              <a
                class="d-flex align-items-center text-start mx-3 ms-0 pb-3 active"
                data-bs-toggle="pill"
                href="#tab-1"
              >
                <h6 class="mt-n1 mb-0">Featured</h6>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="d-flex align-items-center text-start mx-3 pb-3"
                data-bs-toggle="pill"
                href="#tab-2"
              >
                <h6 class="mt-n1 mb-0">Full Time</h6>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="d-flex align-items-center text-start mx-3 me-0 pb-3"
                data-bs-toggle="pill"
                href="#tab-3"
              >
                <h6 class="mt-n1 mb-0">Part Time</h6>
              </a>
            </li>
          </ul>
          <div class="tab-content">
            <div id="tab-1" class="tab-pane fade show p-0 active">
              <div class="job-item p-4 mb-4">
                <div class="row g-4">
                  <div class="col-sm-12 col-md-8 d-flex align-items-center">
                    <img
                      class="flex-shrink-0 img-fluid border rounded"
                      src="img/com-logo-1.jpg"
                      alt=""
                      style="width: 80px; height: 80px"
                    />
                    <div class="text-start ps-4">
                      <h5 class="mb-3">Software Engineer</h5>
                      <span class="text-truncate me-3"
                        ><i class="fa fa-map-marker-alt text-primary me-2"></i
                        >New York, USA</span
                      >
                      <span class="text-truncate me-3"
                        ><i class="far fa-clock text-primary me-2"></i>Full
                        Time</span
                      >
                      <span class="text-truncate me-0"
                        ><i class="far fa-money-bill-alt text-primary me-2"></i
                        >$123 - $456</span
                      >
                    </div>
                  </div>
                  <div
                    class="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center"
                  >
                    <div class="d-flex mb-3">
                      <a class="btn btn-light btn-square me-3" href=""
                        ><i class="far fa-heart text-primary"></i
                      ></a>
                      <a class="btn btn-primary" href="/job-details">Apply Now</a>
                    </div>
                    <small class="text-truncate"
                      ><i class="far fa-calendar-alt text-primary me-2"></i>Date
                      Line: 01 Jan, 2045</small
                    >
                  </div>
                </div>
              </div>
              <div class="job-item p-4 mb-4">
                <div class="row g-4">
                  <div class="col-sm-12 col-md-8 d-flex align-items-center">
                    <img
                      class="flex-shrink-0 img-fluid border rounded"
                      src="img/com-logo-2.jpg"
                      alt=""
                      style="width: 80px; height: 80px"
                    />
                    <div class="text-start ps-4">
                      <h5 class="mb-3">Marketing Manager</h5>
                      <span class="text-truncate me-3"
                        ><i class="fa fa-map-marker-alt text-primary me-2"></i
                        >New York, USA</span
                      >
                      <span class="text-truncate me-3"
                        ><i class="far fa-clock text-primary me-2"></i>Full
                        Time</span
                      >
                      <span class="text-truncate me-0"
                        ><i class="far fa-money-bill-alt text-primary me-2"></i
                        >$123 - $456</span
                      >
                    </div>
                  </div>
                  <div
                    class="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center"
                  >
                    <div class="d-flex mb-3">
                      <a class="btn btn-light btn-square me-3" href=""
                        ><i class="far fa-heart text-primary"></i
                      ></a>
                      <a class="btn btn-primary" href="/job-details">Apply Now</a>
                    </div>
                    <small class="text-truncate"
                      ><i class="far fa-calendar-alt text-primary me-2"></i>Date
                      Line: 01 Jan, 2045</small
                    >
                  </div>
                </div>
              </div>
              <div class="job-item p-4 mb-4">
                <div class="row g-4">
                  <div class="col-sm-12 col-md-8 d-flex align-items-center">
                    <img
                      class="flex-shrink-0 img-fluid border rounded"
                      src="img/com-logo-3.jpg"
                      alt=""
                      style="width: 80px; height: 80px"
                    />
                    <div class="text-start ps-4">
                      <h5 class="mb-3">Product Designer</h5>
                      <span class="text-truncate me-3"
                        ><i class="fa fa-map-marker-alt text-primary me-2"></i
                        >New York, USA</span
                      >
                      <span class="text-truncate me-3"
                        ><i class="far fa-clock text-primary me-2"></i>Full
                        Time</span
                      >
                      <span class="text-truncate me-0"
                        ><i class="far fa-money-bill-alt text-primary me-2"></i
                        >$123 - $456</span
                      >
                    </div>
                  </div>
                  <div
                    class="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center"
                  >
                    <div class="d-flex mb-3">
                      <a class="btn btn-light btn-square me-3" href=""
                        ><i class="far fa-heart text-primary"></i
                      ></a>
                      <a class="btn btn-primary" href="/job-details">Apply Now</a>
                    </div>
                    <small class="text-truncate"
                      ><i class="far fa-calendar-alt text-primary me-2"></i>Date
                      Line: 01 Jan, 2045</small
                    >
                  </div>
                </div>
              </div>
              <div class="job-item p-4 mb-4">
                <div class="row g-4">
                  <div class="col-sm-12 col-md-8 d-flex align-items-center">
                    <img
                      class="flex-shrink-0 img-fluid border rounded"
                      src="img/com-logo-4.jpg"
                      alt=""
                      style="width: 80px; height: 80px"
                    />
                    <div class="text-start ps-4">
                      <h5 class="mb-3">Creative Director</h5>
                      <span class="text-truncate me-3"
                        ><i class="fa fa-map-marker-alt text-primary me-2"></i
                        >New York, USA</span
                      >
                      <span class="text-truncate me-3"
                        ><i class="far fa-clock text-primary me-2"></i>Full
                        Time</span
                      >
                      <span class="text-truncate me-0"
                        ><i class="far fa-money-bill-alt text-primary me-2"></i
                        >$123 - $456</span
                      >
                    </div>
                  </div>
                  <div
                    class="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center"
                  >
                    <div class="d-flex mb-3">
                      <a class="btn btn-light btn-square me-3" href=""
                        ><i class="far fa-heart text-primary"></i
                      ></a>
                      <a class="btn btn-primary" href="/job-details">Apply Now</a>
                    </div>
                    <small class="text-truncate"
                      ><i class="far fa-calendar-alt text-primary me-2"></i>Date
                      Line: 01 Jan, 2045</small
                    >
                  </div>
                </div>
              </div>
              <div class="job-item p-4 mb-4">
                <div class="row g-4">
                  <div class="col-sm-12 col-md-8 d-flex align-items-center">
                    <img
                      class="flex-shrink-0 img-fluid border rounded"
                      src="img/com-logo-5.jpg"
                      alt=""
                      style="width: 80px; height: 80px"
                    />
                    <div class="text-start ps-4">
                      <h5 class="mb-3">Wordpress Developer</h5>
                      <span class="text-truncate me-3"
                        ><i class="fa fa-map-marker-alt text-primary me-2"></i
                        >New York, USA</span
                      >
                      <span class="text-truncate me-3"
                        ><i class="far fa-clock text-primary me-2"></i>Full
                        Time</span
                      >
                      <span class="text-truncate me-0"
                        ><i class="far fa-money-bill-alt text-primary me-2"></i
                        >$123 - $456</span
                      >
                    </div>
                  </div>
                  <div
                    class="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center"
                  >
                    <div class="d-flex mb-3">
                      <a class="btn btn-light btn-square me-3" href=""
                        ><i class="far fa-heart text-primary"></i
                      ></a>
                      <a class="btn btn-primary" href="/job-details">Apply Now</a>
                    </div>
                    <small class="text-truncate"
                      ><i class="far fa-calendar-alt text-primary me-2"></i>Date
                      Line: 01 Jan, 2045</small
                    >
                  </div>
                </div>
              </div>
              <a class="btn btn-primary py-3 px-5" href="">Browse More Jobs</a>
            </div>
            <div id="tab-2" class="tab-pane fade show p-0">
              <div class="job-item p-4 mb-4">
                <div class="row g-4">
                  <div class="col-sm-12 col-md-8 d-flex align-items-center">
                    <img
                      class="flex-shrink-0 img-fluid border rounded"
                      src="img/com-logo-1.jpg"
                      alt=""
                      style="width: 80px; height: 80px"
                    />
                    <div class="text-start ps-4">
                      <h5 class="mb-3">Software Engineer</h5>
                      <span class="text-truncate me-3"
                        ><i class="fa fa-map-marker-alt text-primary me-2"></i
                        >New York, USA</span
                      >
                      <span class="text-truncate me-3"
                        ><i class="far fa-clock text-primary me-2"></i>Full
                        Time</span
                      >
                      <span class="text-truncate me-0"
                        ><i class="far fa-money-bill-alt text-primary me-2"></i
                        >$123 - $456</span
                      >
                    </div>
                  </div>
                  <div
                    class="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center"
                  >
                    <div class="d-flex mb-3">
                      <a class="btn btn-light btn-square me-3" href=""
                        ><i class="far fa-heart text-primary"></i
                      ></a>
                      <a class="btn btn-primary" href="/job-details">Apply Now</a>
                    </div>
                    <small class="text-truncate"
                      ><i class="far fa-calendar-alt text-primary me-2"></i>Date
                      Line: 01 Jan, 2045</small
                    >
                  </div>
                </div>
              </div>
              <div class="job-item p-4 mb-4">
                <div class="row g-4">
                  <div class="col-sm-12 col-md-8 d-flex align-items-center">
                    <img
                      class="flex-shrink-0 img-fluid border rounded"
                      src="img/com-logo-2.jpg"
                      alt=""
                      style="width: 80px; height: 80px"
                    />
                    <div class="text-start ps-4">
                      <h5 class="mb-3">Marketing Manager</h5>
                      <span class="text-truncate me-3"
                        ><i class="fa fa-map-marker-alt text-primary me-2"></i
                        >New York, USA</span
                      >
                      <span class="text-truncate me-3"
                        ><i class="far fa-clock text-primary me-2"></i>Full
                        Time</span
                      >
                      <span class="text-truncate me-0"
                        ><i class="far fa-money-bill-alt text-primary me-2"></i
                        >$123 - $456</span
                      >
                    </div>
                  </div>
                  <div
                    class="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center"
                  >
                    <div class="d-flex mb-3">
                      <a class="btn btn-light btn-square me-3" href=""
                        ><i class="far fa-heart text-primary"></i
                      ></a>
                      <a class="btn btn-primary" href="/job-details">Apply Now</a>
                    </div>
                    <small class="text-truncate"
                      ><i class="far fa-calendar-alt text-primary me-2"></i>Date
                      Line: 01 Jan, 2045</small
                    >
                  </div>
                </div>
              </div>
              <div class="job-item p-4 mb-4">
                <div class="row g-4">
                  <div class="col-sm-12 col-md-8 d-flex align-items-center">
                    <img
                      class="flex-shrink-0 img-fluid border rounded"
                      src="img/com-logo-3.jpg"
                      alt=""
                      style="width: 80px; height: 80px"
                    />
                    <div class="text-start ps-4">
                      <h5 class="mb-3">Product Designer</h5>
                      <span class="text-truncate me-3"
                        ><i class="fa fa-map-marker-alt text-primary me-2"></i
                        >New York, USA</span
                      >
                      <span class="text-truncate me-3"
                        ><i class="far fa-clock text-primary me-2"></i>Full
                        Time</span
                      >
                      <span class="text-truncate me-0"
                        ><i class="far fa-money-bill-alt text-primary me-2"></i
                        >$123 - $456</span
                      >
                    </div>
                  </div>
                  <div
                    class="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center"
                  >
                    <div class="d-flex mb-3">
                      <a class="btn btn-light btn-square me-3" href=""
                        ><i class="far fa-heart text-primary"></i
                      ></a>
                      <a class="btn btn-primary" href="/job-details">Apply Now</a>
                    </div>
                    <small class="text-truncate"
                      ><i class="far fa-calendar-alt text-primary me-2"></i>Date
                      Line: 01 Jan, 2045</small
                    >
                  </div>
                </div>
              </div>
              <div class="job-item p-4 mb-4">
                <div class="row g-4">
                  <div class="col-sm-12 col-md-8 d-flex align-items-center">
                    <img
                      class="flex-shrink-0 img-fluid border rounded"
                      src="img/com-logo-4.jpg"
                      alt=""
                      style="width: 80px; height: 80px"
                    />
                    <div class="text-start ps-4">
                      <h5 class="mb-3">Creative Director</h5>
                      <span class="text-truncate me-3"
                        ><i class="fa fa-map-marker-alt text-primary me-2"></i
                        >New York, USA</span
                      >
                      <span class="text-truncate me-3"
                        ><i class="far fa-clock text-primary me-2"></i>Full
                        Time</span
                      >
                      <span class="text-truncate me-0"
                        ><i class="far fa-money-bill-alt text-primary me-2"></i
                        >$123 - $456</span
                      >
                    </div>
                  </div>
                  <div
                    class="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center"
                  >
                    <div class="d-flex mb-3">
                      <a class="btn btn-light btn-square me-3" href=""
                        ><i class="far fa-heart text-primary"></i
                      ></a>
                      <a class="btn btn-primary" href="/job-details">Apply Now</a>
                    </div>
                    <small class="text-truncate"
                      ><i class="far fa-calendar-alt text-primary me-2"></i>Date
                      Line: 01 Jan, 2045</small
                    >
                  </div>
                </div>
              </div>
              <div class="job-item p-4 mb-4">
                <div class="row g-4">
                  <div class="col-sm-12 col-md-8 d-flex align-items-center">
                    <img
                      class="flex-shrink-0 img-fluid border rounded"
                      src="img/com-logo-5.jpg"
                      alt=""
                      style="width: 80px; height: 80px"
                    />
                    <div class="text-start ps-4">
                      <h5 class="mb-3">Wordpress Developer</h5>
                      <span class="text-truncate me-3"
                        ><i class="fa fa-map-marker-alt text-primary me-2"></i
                        >New York, USA</span
                      >
                      <span class="text-truncate me-3"
                        ><i class="far fa-clock text-primary me-2"></i>Full
                        Time</span
                      >
                      <span class="text-truncate me-0"
                        ><i class="far fa-money-bill-alt text-primary me-2"></i
                        >$123 - $456</span
                      >
                    </div>
                  </div>
                  <div
                    class="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center"
                  >
                    <div class="d-flex mb-3">
                      <a class="btn btn-light btn-square me-3" href=""
                        ><i class="far fa-heart text-primary"></i
                      ></a>
                      <a class="btn btn-primary" href="/job-details">Apply Now</a>
                    </div>
                    <small class="text-truncate"
                      ><i class="far fa-calendar-alt text-primary me-2"></i>Date
                      Line: 01 Jan, 2045</small
                    >
                  </div>
                </div>
              </div>
              <a class="btn btn-primary py-3 px-5" href="">Browse More Jobs</a>
            </div>
            <div id="tab-3" class="tab-pane fade show p-0">
              <div class="job-item p-4 mb-4">
                <div class="row g-4">
                  <div class="col-sm-12 col-md-8 d-flex align-items-center">
                    <img
                      class="flex-shrink-0 img-fluid border rounded"
                      src="img/com-logo-1.jpg"
                      alt=""
                      style="width: 80px; height: 80px"
                    />
                    <div class="text-start ps-4">
                      <h5 class="mb-3">Software Engineer</h5>
                      <span class="text-truncate me-3"
                        ><i class="fa fa-map-marker-alt text-primary me-2"></i
                        >New York, USA</span
                      >
                      <span class="text-truncate me-3"
                        ><i class="far fa-clock text-primary me-2"></i>Full
                        Time</span
                      >
                      <span class="text-truncate me-0"
                        ><i class="far fa-money-bill-alt text-primary me-2"></i
                        >$123 - $456</span
                      >
                    </div>
                  </div>
                  <div
                    class="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center"
                  >
                    <div class="d-flex mb-3">
                      <a class="btn btn-light btn-square me-3" href=""
                        ><i class="far fa-heart text-primary"></i
                      ></a>
                      <a class="btn btn-primary" href="/job-details">Apply Now</a>
                    </div>
                    <small class="text-truncate"
                      ><i class="far fa-calendar-alt text-primary me-2"></i>Date
                      Line: 01 Jan, 2045</small
                    >
                  </div>
                </div>
              </div>
              <div class="job-item p-4 mb-4">
                <div class="row g-4">
                  <div class="col-sm-12 col-md-8 d-flex align-items-center">
                    <img
                      class="flex-shrink-0 img-fluid border rounded"
                      src="img/com-logo-2.jpg"
                      alt=""
                      style="width: 80px; height: 80px"
                    />
                    <div class="text-start ps-4">
                      <h5 class="mb-3">Marketing Manager</h5>
                      <span class="text-truncate me-3"
                        ><i class="fa fa-map-marker-alt text-primary me-2"></i
                        >New York, USA</span
                      >
                      <span class="text-truncate me-3"
                        ><i class="far fa-clock text-primary me-2"></i>Full
                        Time</span
                      >
                      <span class="text-truncate me-0"
                        ><i class="far fa-money-bill-alt text-primary me-2"></i
                        >$123 - $456</span
                      >
                    </div>
                  </div>
                  <div
                    class="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center"
                  >
                    <div class="d-flex mb-3">
                      <a class="btn btn-light btn-square me-3" href=""
                        ><i class="far fa-heart text-primary"></i
                      ></a>
                      <a class="btn btn-primary" href="/job-details">Apply Now</a>
                    </div>
                    <small class="text-truncate"
                      ><i class="far fa-calendar-alt text-primary me-2"></i>Date
                      Line: 01 Jan, 2045</small
                    >
                  </div>
                </div>
              </div>
              <div class="job-item p-4 mb-4">
                <div class="row g-4">
                  <div class="col-sm-12 col-md-8 d-flex align-items-center">
                    <img
                      class="flex-shrink-0 img-fluid border rounded"
                      src="img/com-logo-3.jpg"
                      alt=""
                      style="width: 80px; height: 80px"
                    />
                    <div class="text-start ps-4">
                      <h5 class="mb-3">Product Designer</h5>
                      <span class="text-truncate me-3"
                        ><i class="fa fa-map-marker-alt text-primary me-2"></i
                        >New York, USA</span
                      >
                      <span class="text-truncate me-3"
                        ><i class="far fa-clock text-primary me-2"></i>Full
                        Time</span
                      >
                      <span class="text-truncate me-0"
                        ><i class="far fa-money-bill-alt text-primary me-2"></i
                        >$123 - $456</span
                      >
                    </div>
                  </div>
                  <div
                    class="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center"
                  >
                    <div class="d-flex mb-3">
                      <a class="btn btn-light btn-square me-3" href=""
                        ><i class="far fa-heart text-primary"></i
                      ></a>
                      <a class="btn btn-primary" href="/job-details">Apply Now</a>
                    </div>
                    <small class="text-truncate"
                      ><i class="far fa-calendar-alt text-primary me-2"></i>Date
                      Line: 01 Jan, 2045</small
                    >
                  </div>
                </div>
              </div>
              <div class="job-item p-4 mb-4">
                <div class="row g-4">
                  <div class="col-sm-12 col-md-8 d-flex align-items-center">
                    <img
                      class="flex-shrink-0 img-fluid border rounded"
                      src="img/com-logo-4.jpg"
                      alt=""
                      style="width: 80px; height: 80px"
                    />
                    <div class="text-start ps-4">
                      <h5 class="mb-3">Creative Director</h5>
                      <span class="text-truncate me-3"
                        ><i class="fa fa-map-marker-alt text-primary me-2"></i
                        >New York, USA</span
                      >
                      <span class="text-truncate me-3"
                        ><i class="far fa-clock text-primary me-2"></i>Full
                        Time</span
                      >
                      <span class="text-truncate me-0"
                        ><i class="far fa-money-bill-alt text-primary me-2"></i
                        >$123 - $456</span
                      >
                    </div>
                  </div>
                  <div
                    class="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center"
                  >
                    <div class="d-flex mb-3">
                      <a class="btn btn-light btn-square me-3" href=""
                        ><i class="far fa-heart text-primary"></i
                      ></a>
                      <a class="btn btn-primary" href="/job-details">Apply Now</a>
                    </div>
                    <small class="text-truncate"
                      ><i class="far fa-calendar-alt text-primary me-2"></i>Date
                      Line: 01 Jan, 2045</small
                    >
                  </div>
                </div>
              </div>
              <div class="job-item p-4 mb-4">
                <div class="row g-4">
                  <div class="col-sm-12 col-md-8 d-flex align-items-center">
                    <img
                      class="flex-shrink-0 img-fluid border rounded"
                      src="img/com-logo-5.jpg"
                      alt=""
                      style="width: 80px; height: 80px"
                    />
                    <div class="text-start ps-4">
                      <h5 class="mb-3">Wordpress Developer</h5>
                      <span class="text-truncate me-3"
                        ><i class="fa fa-map-marker-alt text-primary me-2"></i
                        >New York, USA</span
                      >
                      <span class="text-truncate me-3"
                        ><i class="far fa-clock text-primary me-2"></i>Full
                        Time</span
                      >
                      <span class="text-truncate me-0"
                        ><i class="far fa-money-bill-alt text-primary me-2"></i
                        >$123 - $456</span
                      >
                    </div>
                  </div>
                  <div
                    class="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center"
                  >
                    <div class="d-flex mb-3">
                      <a class="btn btn-light btn-square me-3" href=""
                        ><i class="far fa-heart text-primary"></i
                      ></a>
                      <a class="btn btn-primary" href="/job-details">Apply Now</a>
                    </div>
                    <small class="text-truncate"
                      ><i class="far fa-calendar-alt text-primary me-2"></i>Date
                      Line: 01 Jan, 2045</small
                    >
                  </div>
                </div>
              </div>
              <a class="btn btn-primary py-3 px-5" href="">Browse More Jobs</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Jobs End -->

    <!-- Testimonial Start -->
    <div class="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div class="container">
        <h1 class="text-center mb-5">Our Clients Say!!!</h1>
        <div class="owl-carousel testimonial-carousel">
          <div class="testimonial-item bg-light rounded p-4">
            <i class="fa fa-quote-left fa-2x text-primary mb-3"></i>
            <p>
              Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor
              stet amet eirmod eos labore diam
            </p>
            <div class="d-flex align-items-center">
              <img
                class="img-fluid flex-shrink-0 rounded"
                src="img/testimonial-1.jpg"
                style="width: 50px; height: 50px"
              />
              <div class="ps-3">
                <h5 class="mb-1">Client Name</h5>
                <small>Profession</small>
              </div>
            </div>
          </div>
          <div class="testimonial-item bg-light rounded p-4">
            <i class="fa fa-quote-left fa-2x text-primary mb-3"></i>
            <p>
              Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor
              stet amet eirmod eos labore diam
            </p>
            <div class="d-flex align-items-center">
              <img
                class="img-fluid flex-shrink-0 rounded"
                src="img/testimonial-2.jpg"
                style="width: 50px; height: 50px"
              />
              <div class="ps-3">
                <h5 class="mb-1">Client Name</h5>
                <small>Profession</small>
              </div>
            </div>
          </div>
          <div class="testimonial-item bg-light rounded p-4">
            <i class="fa fa-quote-left fa-2x text-primary mb-3"></i>
            <p>
              Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor
              stet amet eirmod eos labore diam
            </p>
            <div class="d-flex align-items-center">
              <img
                class="img-fluid flex-shrink-0 rounded"
                src="img/testimonial-3.jpg"
                style="width: 50px; height: 50px"
              />
              <div class="ps-3">
                <h5 class="mb-1">Client Name</h5>
                <small>Profession</small>
              </div>
            </div>
          </div>
          <div class="testimonial-item bg-light rounded p-4">
            <i class="fa fa-quote-left fa-2x text-primary mb-3"></i>
            <p>
              Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor
              stet amet eirmod eos labore diam
            </p>
            <div class="d-flex align-items-center">
              <img
                class="img-fluid flex-shrink-0 rounded"
                src="img/testimonial-4.jpg"
                style="width: 50px; height: 50px"
              />
              <div class="ps-3">
                <h5 class="mb-1">Client Name</h5>
                <small>Profession</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Testimonial End -->

    <!-- Footer Start -->
    <div
      class="container-fluid bg-dark text-white-50 footer pt-5 mt-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div class="container py-5">
        <div class="row g-5">
          <div class="col-lg-3 col-md-6">
            <h5 class="text-white mb-4">Company</h5>
            <a class="btn btn-link text-white-50" href="">About Us</a>
            <a class="btn btn-link text-white-50" href="">Contact Us</a>
            <a class="btn btn-link text-white-50" href="">Our Services</a>
            <a class="btn btn-link text-white-50" href="">Privacy Policy</a>
            <a class="btn btn-link text-white-50" href="">Terms & Condition</a>
          </div>
          <div class="col-lg-3 col-md-6">
            <h5 class="text-white mb-4">Quick Links</h5>
            <a class="btn btn-link text-white-50" href="">About Us</a>
            <a class="btn btn-link text-white-50" href="">Contact Us</a>
            <a class="btn btn-link text-white-50" href="">Our Services</a>
            <a class="btn btn-link text-white-50" href="">Privacy Policy</a>
            <a class="btn btn-link text-white-50" href="">Terms & Condition</a>
          </div>
          <div class="col-lg-3 col-md-6">
            <h5 class="text-white mb-4">Contact</h5>
            <p class="mb-2">
              <i class="fa fa-map-marker-alt me-3"></i>KG 208 st, Kimironko Kigali RWANDA
            </p>
            <p class="mb-2">
              <i class="fa fa-phone-alt me-3"></i>0789 263 354
            </p>
            <p class="mb-2">
              <i class="fa fa-envelope me-3"></i>info@umurava.africa
            </p>
            <div class="d-flex pt-2">
              <a class="btn btn-outline-light btn-social" href=""
                ><i class="fab fa-twitter"></i
              ></a>
              <a class="btn btn-outline-light btn-social" href=""
                ><i class="fab fa-facebook-f"></i
              ></a>
              <a class="btn btn-outline-light btn-social" href=""
                ><i class="fab fa-youtube"></i
              ></a>
              <a class="btn btn-outline-light btn-social" href=""
                ><i class="fab fa-linkedin-in"></i
              ></a>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <h5 class="text-white mb-4">Newsletter</h5>
            <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
            <div class="position-relative mx-auto" style="max-width: 400px">
              <input
                class="form-control bg-transparent w-100 py-3 ps-4 pe-5"
                type="text"
                placeholder="Your email"
              />
              <button
                type="button"
                class="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
              >
                SignUp
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="copyright">
          <div class="row">
            <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy; <a class="border-bottom" href="#">umurava Africa</a>, All
              Right Reserved.

              <!--/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. ***/-->
              <!-- Designed By <a class="border-bottom" href="https://htmlcodex.com">HTML Codex</a> -->
            </div>
            <div class="col-md-6 text-center text-md-end">
              <div class="footer-menu">
                <a href="">Home</a>
                <a href="">Cookies</a>
                <a href="">Help</a>
                <a href="">FQAs</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer End -->

    <!-- Back to Top -->
    <a href="#" class="btn btn-lg btn-primary btn-lg-square back-to-top"
      ><i class="bi bi-arrow-up"></i
    ></a>
  </div>
</template>
